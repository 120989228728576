/* Machine Shop Timeline Styles */

/* General body styles */
body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
}

/* Timeline container */
.vis-timeline {
  border: 2px solid #444;
  background: #e6e6e6;
  font-family: Arial, sans-serif;
  font-size: 12pt;
}

/* Timeline items */
.vis-item {
  border-color: #007acc;
  background-color: #cce5ff;
  font-size: 14px;
  color: #003f5c;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
  padding: 4px;
  border-radius: 4px;
}

/* Adjust line and dot items */
.vis-item,
.vis-item.vis-line {
  border-width: 2px;
}

.vis-item.vis-dot {
  border-width: 8px;
  border-radius: 8px;
  background-color: #007acc;
}

/* Selected items */
.vis-item.vis-selected {
  border-color: #005b99;
  background-color: #99ccff;
}

/* Time axis text */
.vis-time-axis .vis-text {
  color: #444;
  font-size: 12px;
}

.vis-time-axis .vis-text.vis-major {
  font-weight: bold;
  color: #222;
}

/* Time axis gridlines */
.vis-time-axis .vis-grid.vis-minor {
  border-width: 1px;
  border-color: #ccc;
}

.vis-time-axis .vis-grid.vis-major {
  border-width: 2px;
  border-color: #999;
}

/* Group labels */
.vis-label {
  font-size: 14px;
  color: #333;
  font-weight: bold;
  background: #d9d9d9;
  border: 1px solid #999;
  padding: 5px;
  border-radius: 4px;
}
